import React from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import Select, { StylesConfig } from 'react-select'

import {
  Drawer,
  DrawerContainerProps,
  Checkbox,
  Button,
  Rating,
} from 'packages/common'
import { ReviewSource } from 'packages/grimoire/src/review/review.types'

import styles from './FiltersDrawer.module.scss'

export type UnitOption = {
  label: string
  value: string
}

type PickedDrawerProps = Pick<
  DrawerContainerProps,
  'afterExit' | 'forceClose' | 'isOpen'
>

export type FiltersDrawerProps = {
  onApply: (
    selectedChannels: ReviewSource[],
    selectedRatings: number[],
    selectedUnits: UnitOption[],
  ) => void
  selectedChannels: ReviewSource[]
  selectedRatings: number[]
  selectedUnits: UnitOption[]
  units: UnitOption[]
} & PickedDrawerProps

export const RATINGS = [5, 4, 3, 2, 1] as const
export const CHANNELS_MAP: Record<ReviewSource, string> = {
  [ReviewSource.AIRBNB]: 'Airbnb',
  [ReviewSource.BOOKING]: 'Booking',
  [ReviewSource.VACASA]: 'Vacasa',
  [ReviewSource.VRBO]: 'Vrbo',
}
export enum FilterDrawerTestIds {
  applyButton = 'FilterDrawer__applyButton',
  channelCheckboxes = 'FilterDrawer__channelCheckboxes',
  ratingCheckboxes = 'FilterDrawer__ratingCheckboxes',
}

const UNIT_SELECT_STYLES: StylesConfig<UnitOption> = {
  control: styles => ({
    ...styles,
    display: 'flex',
    flexWrap: 'nowrap',
  }),
  valueContainer: styles => ({
    ...styles,
    display: 'flex',
    flexWrap: 'nowrap',
  }),
}

type FormValues = {
  selectedChannels: ReviewSource[]
  selectedRatings: number[]
  selectedUnits: UnitOption[]
}

export const FiltersDrawer: React.FC<FiltersDrawerProps> = ({
  afterExit,
  forceClose,
  isOpen,
  selectedChannels,
  selectedRatings,
  selectedUnits,
  onApply,
  units,
}) => {
  const { reset, handleSubmit, control, getValues, setValue } =
    useForm<FormValues>({
      defaultValues: {
        selectedChannels,
        selectedRatings,
        selectedUnits,
      },
    })

  const onSelectChannel = (event: React.ChangeEvent<HTMLInputElement>) => {
    const channel = event.target.value as ReviewSource
    const selectedChannels = getValues('selectedChannels')
    if (selectedChannels.includes(channel)) {
      setValue(
        'selectedChannels',
        selectedChannels.filter(c => c !== channel),
      )
    } else {
      setValue('selectedChannels', [...selectedChannels, channel])
    }
  }

  const onSelectRating = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rating = Number(event.target.value)
    const selectedRatings = getValues('selectedRatings')
    if (selectedRatings.includes(rating)) {
      setValue(
        'selectedRatings',
        selectedRatings.filter(r => r !== rating),
      )
    } else {
      setValue('selectedRatings', [...selectedRatings, rating])
    }
  }

  const submit: SubmitHandler<FormValues> = React.useCallback(
    data => {
      onApply(data.selectedChannels, data.selectedRatings, data.selectedUnits)
    },
    [onApply],
  )

  React.useEffect(() => {
    reset({
      selectedChannels,
      selectedRatings,
      selectedUnits,
    })
  }, [reset, selectedChannels, selectedRatings, selectedUnits])

  return (
    <Drawer isOpen={isOpen} afterExit={afterExit} forceClose={forceClose}>
      <div className={styles.drawerContainer}>
        <div className={styles.header}>Add filters</div>
        <div className={styles.unitContainer}>
          <div className={styles.filterName}>By unit</div>
          <Controller
            control={control}
            name="selectedUnits"
            render={field => (
              <Select
                {...field}
                id="unit-select"
                options={units}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                isMulti={true}
                hideSelectedOptions={true}
                closeMenuOnSelect={false}
                isClearable={false}
                styles={UNIT_SELECT_STYLES}
              />
            )}
          />
        </div>
        <div className={styles.ratingAndChannelContainer}>
          <div>
            <div className={styles.filterName}>By rating</div>
            <Controller
              control={control}
              name="selectedRatings"
              render={field => (
                <div
                  className={styles.checkboxes}
                  data-testid={FilterDrawerTestIds.ratingCheckboxes}
                >
                  {RATINGS.map(rating => (
                    <Checkbox
                      key={rating}
                      label={<Rating value={rating} />}
                      value={rating}
                      checked={field.value.includes(rating)}
                      onChange={onSelectRating}
                    />
                  ))}
                </div>
              )}
            />
          </div>
          <div>
            <div className={styles.filterName}>By channel</div>
            <Controller
              control={control}
              name="selectedChannels"
              render={field => (
                <div
                  className={styles.checkboxes}
                  data-testid={FilterDrawerTestIds.channelCheckboxes}
                >
                  {Object.entries(CHANNELS_MAP).map(([channel, label]) => (
                    <Checkbox
                      key={channel}
                      label={label}
                      value={channel}
                      checked={field.value.includes(channel)}
                      onChange={onSelectChannel}
                    />
                  ))}
                </div>
              )}
            />
          </div>
        </div>
        <Button
          dataTestId={FilterDrawerTestIds.applyButton}
          onClick={handleSubmit(submit)}
          className={styles.applyButton}
        >
          Apply
        </Button>
      </div>
    </Drawer>
  )
}
