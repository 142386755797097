import {
  findAllRelationshipIds,
  findRelationshipId,
  transformNormalizedToTyped,
} from 'packages/utils/store'

import {
  Unit,
  RawUnit,
  UnitPreference,
  RawUnitPreference,
  UnitAttributeNames,
  UnitPreferenceKind,
  UnitResponse,
} from './units.types'

export const hydrateRawUnit = (rawUnit: RawUnit): Unit => {
  // populate Reservations
  const currentReservationId =
    findRelationshipId(rawUnit, 'currentReservation') || ''
  const customInspectionItemIds = findAllRelationshipIds(
    rawUnit,
    'customInspectionItems',
  )
  const nextReservationId = findRelationshipId(rawUnit, 'nextReservation') || ''

  const oncallUserId = findRelationshipId(rawUnit, 'oncallUser') || ''
  
  const managerUserId = findRelationshipId(rawUnit, 'manager') || ''

  const unit = transformNormalizedToTyped<Unit>(rawUnit, UnitAttributeNames)

  return {
    ...unit,
    currentReservationId,
    customInspectionItemIds,
    managerUserId,
    nextReservationId,
    oncallUserId,
  }
}

export type MiniUnitPreference = Pick<UnitPreference, 'alias' | 'id'> & {
  housekeeperId: string
}

export const hydrateMiniUnitPreference = (
  raw: RawUnitPreference,
): MiniUnitPreference => {
  const housekeeperId = raw.relationships.housekeeper.data.id
  const alias = UnitPreferenceKind[raw.attributes.alias.toLowerCase()]

  return {
    alias,
    housekeeperId,
    id: raw.id,
  }
}

/**
 * Empty data in the shape of the Units service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedUnitsData: UnitResponse = Object.freeze({
  clean: {},
  housekeeper: {},
  housekeeperUnitPreference: {},
  lockBox: {},
  unit: {},
  zone: {},
})
