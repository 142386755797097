import styled from '@emotion/styled'

const PAGE_WIDTH = '1200px'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ${PAGE_WIDTH};
  margin: auto;
`

export const HeaderWrapper = styled.h2`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const HeaderText = styled.div`
  font-size: 28px;
  margin: auto;
  max-width: ${PAGE_WIDTH};
  text-transform: uppercase;
  font-weight: 700;
`

export const OverviewPageLoaderWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 200px;
`
