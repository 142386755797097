import { produce } from 'immer'
import { merge } from 'lodash/fp'
import { ActionType, getType } from 'typesafe-actions'

import { fetchTaskByIdAction } from '../tasks/actions'
import { fetchVisitByIdAction } from '../visits/actions'
import { clearZoneAction } from '../zones/actions'
import { InspectionFlagsState } from './inspectionFlags.types'

export const initialState: InspectionFlagsState = {
  data: {},
  error: undefined,
}

const actions = {
  clearZoneAction,
  fetchTaskByIdAction,
  fetchVisitByIdAction,
}

type InspectionFlagsActionsTypes = ActionType<typeof actions>

export const inspectionFlagsReducer = (
  state = initialState,
  action: InspectionFlagsActionsTypes,
): InspectionFlagsState =>
  produce(state, (draft: InspectionFlagsState) => {
    switch (action.type) {
      case getType(fetchTaskByIdAction.success):
      case getType(fetchVisitByIdAction.success): {
        const normalized = action.payload?.normalized || {}

        if (normalized.inspectionFlag) {
          Object.values(normalized.inspectionFlag).forEach(incomingFlag => {
            const existingFlag = state.data[incomingFlag.id] || {}
            const mergedFlag = merge(existingFlag, incomingFlag)
            draft.data[incomingFlag.id] = mergedFlag
          })
        }

        return
      }

      // clear all data when switching the current zone
      case getType(actions.clearZoneAction): {
        draft.data = {}
        return
      }
    }
  })
