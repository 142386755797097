import { ReviewAttributeNames } from 'packages/grimoire/src/review'
import { createDateObject, subDays } from 'packages/utils/dateHelpers'
import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  NormalizedReviewListApiResponse,
  NormalizedReviewsApiResponse,
  RawReview,
  Review,
  ReviewResponse,
} from './reviews.types'

const REVIEW_DATE_SINCE_DAYS_AGO = 28

/**
 * Empty data in the shape of the Review service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedReviewData = Object.freeze<ReviewResponse>({
  guestReview: {},
})

export const emptyReviewResponse: NormalizedReviewsApiResponse = Object.freeze({
  normalized: emptyNormalizedReviewData,
  raw: { data: [] },
})

export const emptyNormalizedReviewListData = Object.freeze({
  guestReview: {},
  unit: {},
})

export const emptyReviewListResponse: NormalizedReviewListApiResponse =
  Object.freeze({
    normalized: emptyNormalizedReviewListData,
    raw: { data: [] },
  })

export const hydrateRawReview = (rawReview: RawReview): Review =>
  transformNormalizedToTyped<Review>(rawReview, ReviewAttributeNames)

export const getReviewDateSince = (): Date =>
  subDays(createDateObject(), REVIEW_DATE_SINCE_DAYS_AGO)
