import styled from '@emotion/styled'
import { equals, startCase } from 'lodash/fp'
import React, { useEffect } from 'react'

import {
  Button,
  Drawer,
  DrawerContainerProps,
  RadioButtons,
} from 'packages/common'
import { useI18n } from 'packages/i18n'
import { colors } from 'packages/styles'
import {
  DateFormat,
  format,
  isToday,
  formatLocalized,
  createDateObject,
} from 'packages/utils/dateHelpers'

import {
  OccupancySlugMap,
  OccupancyType,
} from 'app/hkhub/components/schedule/components/units/utils'
import { Slugs } from 'app/hkhub/i18n'

export const occupancyOptions = [
  OccupancyType.ARRIVAL,
  OccupancyType.ARRIVAL_PLUS_DEPARTURE,
  OccupancyType.DEPARTURE,
  OccupancyType.STAYOVER,
  OccupancyType.VACASA_HOLD,
  OccupancyType.VACANT,
]

const DrawerContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  min-width: 320px;
  padding: 32px;
  padding-top: 48px;
`

const DrawerTitle = styled.div`
  color: ${colors.dusk};
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 24px;
`

const StyledRadioButtons = styled(RadioButtons)`
  padding-bottom: 32px;
`

const Label = styled.span`
  font-size: 16px;
`

const TodayText = styled.span`
  color: ${colors.dusk40};
  padding-left: 4px;
`

/** Small Component to display the date with correct styles as RadioButton labels */
const DateDisplay: React.FC<{ date: string }> = ({ date }) => {
  const { ut } = useI18n()
  const day = formatLocalized(date, DateFormat.DayNameFull)
  const today = isToday(date)

  return (
    <Label>
      {day}
      {today && <TodayText>({ut(Slugs.today)})</TodayText>}
    </Label>
  )
}

const useTranslations = () => {
  const { t, ut } = useI18n()

  return {
    addFilter: t(Slugs.addFilter),
    apply: ut(Slugs.apply),
    day: ut(Slugs.day),
    occupancy: ut(Slugs.occupancy),
    [OccupancyType.ARRIVAL]: ut(OccupancySlugMap[OccupancyType.ARRIVAL]),
    [OccupancyType.DEPARTURE]: ut(OccupancySlugMap[OccupancyType.DEPARTURE]),
    [OccupancyType.ARRIVAL_PLUS_DEPARTURE]: t(
      OccupancySlugMap[OccupancyType.ARRIVAL_PLUS_DEPARTURE],
    ),

    [OccupancyType.STAYOVER]: ut(OccupancySlugMap[OccupancyType.STAYOVER]),
    [OccupancyType.VACASA_HOLD]: startCase(
      t(OccupancySlugMap[OccupancyType.VACASA_HOLD]),
    ),
    [OccupancyType.VACANT]: ut(OccupancySlugMap[OccupancyType.VACANT]),
  }
}

export enum OccupancyDrawerTestIds {
  applyBtn = 'OccupancyDrawer__applyBtn',
  container = 'OccupancyDrawer__container',
  dateSelector = 'OccupancyDrawer__dateSelector',
  occupancySelector = 'OccupancyDrawer__occupancySelector',
}

type PickedDrawerProps = Pick<
  DrawerContainerProps,
  'afterExit' | 'forceClose' | 'isOpen'
>

export type OccupancyDrawerProps = {
  activeSelectedDate?: string
  activeSelectedOccupancy?: OccupancyType
  dateRange: Date[]
  onFiltersApplied: (date: string, occupancyType: OccupancyType) => void
} & PickedDrawerProps

export const OccupancyDrawer: React.FC<OccupancyDrawerProps> = ({
  activeSelectedDate,
  activeSelectedOccupancy,
  afterExit,
  dateRange,
  forceClose,
  isOpen,
  onFiltersApplied,
}) => {
  const strings = useTranslations()

  const dateStrings = dateRange.map(date =>
    format(date, DateFormat.ApiUtcShort),
  )
  const [selectedDate, setSelectedDate] = React.useState(dateStrings[0])

  const [selectedOccupancy, setSelectedOccupancy] = React.useState(
    OccupancyType.ARRIVAL,
  )

  const onApply = () => {
    onFiltersApplied(selectedDate, selectedOccupancy)
  }

  useEffect(() => {
    if (typeof activeSelectedDate !== 'undefined') {
      const date = createDateObject(
        `20${activeSelectedDate.split('/')[2]}-${
          activeSelectedDate.split('/')[0]
        }-${activeSelectedDate.split('/')[1]}`,
      )
      setSelectedDate(format(date, DateFormat.ApiUtcShort))
    }
  }, [activeSelectedDate])

  useEffect(() => {
    if (typeof activeSelectedOccupancy !== 'undefined') {
      setSelectedOccupancy(activeSelectedOccupancy)
    }
  }, [activeSelectedOccupancy])

  return (
    <Drawer afterExit={afterExit} forceClose={forceClose} isOpen={isOpen}>
      <DrawerContainer data-testid={OccupancyDrawerTestIds.container}>
        <div>
          <DrawerTitle>{strings.addFilter}</DrawerTitle>

          <StyledRadioButtons
            dataTestId={OccupancyDrawerTestIds.dateSelector}
            getOptionLabel={date => <DateDisplay date={date} />}
            onChange={setSelectedDate}
            options={dateStrings}
            selectedValue={selectedDate}
            title={strings.day}
            valueComparator={equals}
          />

          <StyledRadioButtons
            dataTestId={OccupancyDrawerTestIds.occupancySelector}
            getOptionLabel={occupancy => strings[occupancy]}
            onChange={setSelectedOccupancy}
            options={occupancyOptions}
            selectedValue={selectedOccupancy}
            title={strings.occupancy}
            valueComparator={equals}
          />
        </div>

        <Button
          block={true}
          dataTestId={OccupancyDrawerTestIds.applyBtn}
          onClick={onApply}
          buttonType={'primary'}
        >
          {strings.apply}
        </Button>
      </DrawerContainer>
    </Drawer>
  )
}
