import React from 'react'

import { ExternalLink, Rating } from 'packages/common/'
import { IconName } from 'packages/iconic'
import { getAdminViewUnitTicketsURL } from 'packages/utils/links'
import { getAdminViewReservationUrl } from 'packages/utils/links'

import { Review } from 'app/hkhub/store/reviews/reviews.types'

import { useTranslations } from './ReviewDetail.constants'
import * as St from './ReviewDetail.styles'

export type ReviewDetailProps = {
  review: Review
}

export const ReviewDetail: React.FC<ReviewDetailProps> = React.memo(
  ({ review }) => {
    const strings = useTranslations()

    const ratings = [
      { label: strings.reviewOverallRating, value: review.overallRating },
      { label: strings.cleanliness, value: review.cleanlinessRating },
      { label: strings.location, value: review.locationRating },
      { label: strings.wifi, value: review.wifiRating },
      {
        label: strings.propertyCondition,
        value: review.propertyConditionRating,
      },
      { label: strings.service, value: review.serviceRating },
      { label: strings.communication, value: review.communicationRating },
      { label: strings.accuracy, value: review.accuracyRating },
      { label: strings.checkin, value: review.checkinRating },
      { label: strings.value, value: review.valueRating },
      { label: strings.onlineListing, value: review.onlineListingRating },
      { label: strings.staff, value: review.staffRating },
      { label: strings.comfort, value: review.comfortRating },
      { label: strings.facilities, value: review.facilitiesRating },
    ].filter(rating => rating.value != null)

    return (
      <St.ReviewContainer>
        <St.ContentSection>
          {review.reviewHeadline && (
            <St.ReviewTitle>{review.reviewHeadline}</St.ReviewTitle>
          )}

          <St.List>
            {ratings.map((rating, index) => (
              <St.Item
                key={index}
                isBold={rating.label === strings.reviewOverallRating}
              >
                {rating.label}: <Rating value={rating.value as number} />
              </St.Item>
            ))}
          </St.List>

          {review.publicReviewText && (
            <St.ReviewSection>
              <St.SectionTitle>{strings.publicReview}</St.SectionTitle>
              <St.ReviewText>{review.publicReviewText}</St.ReviewText>
            </St.ReviewSection>
          )}

          {review.privateReviewText && (
            <St.ReviewSection>
              <St.SectionTitle>{strings.privateReview}</St.SectionTitle>
              <St.ReviewText>{review.privateReviewText}</St.ReviewText>
            </St.ReviewSection>
          )}

          {review.reviewReply && (
            <St.ReviewSection>
              <St.SectionTitle>{strings.reviewReply}</St.SectionTitle>
              <St.ReviewText>{review.reviewReply}</St.ReviewText>
            </St.ReviewSection>
          )}

          <St.MetadataSection>
            <St.LinksContainer>
              <St.DetailIcon icon={IconName.tag} size={16} />
              <St.Details>
                <St.SectionSubTitle>{strings.unitTickets}</St.SectionSubTitle>

                <ExternalLink
                  text={strings.viewTickets}
                  url={getAdminViewUnitTicketsURL(review.unitId || '')}
                />
              </St.Details>
            </St.LinksContainer>

            {review.reservationId && (
              <St.LinksContainer>
                <St.DetailIcon icon={IconName.home} size={16} />
                <St.Details>
                  <St.SectionSubTitle>{strings.reservation}</St.SectionSubTitle>

                  <ExternalLink
                    text={strings.viewReservation}
                    url={getAdminViewReservationUrl(review.reservationId || '')}
                  />
                </St.Details>
              </St.LinksContainer>
            )}

            <St.ReviewDate>
              <b>{strings.reviewDate}:&nbsp;</b>
              {review.reviewDate}
            </St.ReviewDate>
          </St.MetadataSection>
        </St.ContentSection>
      </St.ReviewContainer>
    )
  },
)
