import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'packages/common'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { fetchDataForHighlights } from 'app/hkhub/store/highlights/actions/fetchDataForHighlights'
import { getDataForHighlights } from 'app/hkhub/store/highlights/selectors/getDataForHighlights'
import { AppDispatch } from 'app/hkhub/store/store'
import { getAuthUser } from 'app/hkhub/store/users/selectors'

import { HighlightsTable } from '../../components/HighlightsTable/HighlightsTable'
import { Highlight } from '../../components/HighlightsTable/HighlightsTable.types'
import * as St from './OverviewPage.styles'

export enum OverviewPageTestIds {
  container = 'HKhub__OverviewPage__container',
}

export type OverviewPageProps = {}

export const OverviewPage: React.FC<OverviewPageProps> = React.memo(() => {
  const { t } = useI18n()
  const dispatch = useDispatch<AppDispatch>()
  const authUser = useSelector(getAuthUser)
  const [isLoading, setIsLoading] = useState(true)
  const highlights = useSelector(getDataForHighlights)

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)
      await dispatch(fetchDataForHighlights({ userId: authUser?.id ?? '' }))
      setIsLoading(false)
    }

    loadData()
  }, [dispatch, authUser?.id])

  return (
    <St.Content data-testid={OverviewPageTestIds.container}>
      <St.HeaderWrapper>
        <St.HeaderText>{t(Slugs.highlights)}</St.HeaderText>
      </St.HeaderWrapper>
      {isLoading ? (
        <St.OverviewPageLoaderWrapper>
          <Loader />
        </St.OverviewPageLoaderWrapper>
      ) : (
        <HighlightsTable highlights={highlights as Highlight[]} />
      )}
    </St.Content>
  )
})
