import { produce } from 'immer'
import { ActionType, getType } from 'typesafe-actions'

import { getIdAndDataTypeFromAction } from 'packages/utils/store'

import { clearZoneAction } from 'app/hkhub/store/zones/actions'

import { fetchTaskByIdAction, wsTaskDeactivatedAction } from '../tasks/actions'
import { emptyNormalizedTasksData } from '../tasks/tasks.utils'
import { fetchVisitByIdAction, fetchVisitsByZoneAndDateAction } from './actions'
import { isRawVisit } from './utils'
import { RawVisit, VisitsState } from './visits.types'

export const initialState: VisitsState = {
  data: {},
}

const actions = {
  clearZoneAction,
  fetchTaskByIdAction,
  fetchVisitByIdAction,
  fetchVisitsByZoneAndDateAction,
  wsTaskDeactivatedAction,
}

type VisitsActionsTypes = ActionType<typeof actions>

export const visitsReducer = (
  state = initialState,
  action: VisitsActionsTypes,
): VisitsState =>
  produce(state, (draft: VisitsState) => {
    switch (action.type) {
      case getType(actions.fetchTaskByIdAction.success):
      case getType(actions.fetchVisitByIdAction.success): {
        const [id, visit] = getIdAndDataTypeFromAction<RawVisit>(action, 'task')

        if (!!visit && isRawVisit(visit)) {
          draft.data[id] = visit
        }

        return
      }

      case getType(actions.fetchVisitsByZoneAndDateAction.success): {
        const normalized =
          action.payload?.normalized || emptyNormalizedTasksData

        Object.values(normalized.task || {}).forEach(task => {
          if (isRawVisit(task)) {
            draft.data[task.id] = task
          }
        })

        return
      }

      // clear all data when switching the current zone
      case getType(actions.clearZoneAction): {
        draft.data = {}
        return
      }

      //------------------------------------------------
      // WebSockets Events
      //------------------------------------------------
      case getType(actions.wsTaskDeactivatedAction): {
        const taskId = action?.payload?.taskId
        if (taskId) {
          delete draft.data[taskId]
        }

        return
      }
    }
  })
