import { createSelector } from '@reduxjs/toolkit'

import { ApplicationState } from '../../store'
import { InspectionFlag } from '../inspectionFlags.types'
import { hydrateRawInspectionFlag } from '../inspectionFlags.utils'

export const getInspectionFlagsByIds = createSelector(
  [
    (state: ApplicationState) => state.inspectionFlags.data,
    (_, flagIds: string[]) => flagIds,
  ],
  (inspectionFlags, flagIds): InspectionFlag[] => {
    return flagIds
      .map(id => inspectionFlags[id])
      .filter(Boolean)
      .map(hydrateRawInspectionFlag)
  },
)
