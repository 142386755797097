import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  width: 100%;
`

export const ContentSection = styled.div`
  padding: 16px;

  @media (min-width: 768px) {
    padding: 24px;
  }
`

export const ReviewTitle = styled.h2`
  ${text.headingMediumCaps};
  margin-bottom: 16px;
  word-break: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`

export const ReviewSection = styled.div`
  margin: 24px 0;
`

export const SectionTitle = styled.div`
  ${text.bodyBoldRegular};
  margin-bottom: 8px;
`

export const ReviewText = styled.div`
  ${text.bodyRegularDefault};
  margin: 16px 0;
  padding: 16px;
  background: ${colors.midnight10};
  border-radius: 4px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  overflow: hidden;
  position: relative;
`

export const MetadataSection = styled.div`
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid ${colors.midnight10};
`

export const ReviewDate = styled.div`
  ${text.bodyRegularSmall};
  color: ${colors.midnight};
  margin-top: 16px;
`

export const Details = styled.div`
  padding-left: 8px;
  width: 100%;
`

export const DetailIcon = styled(SvgIcon)`
  color: ${colors.midnight70};
`

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  padding-left: 8px;
`

export const UnitInfoSection = styled.div`
  margin: 24px 16px 24px 24px;
`

export const SectionSubTitle = styled.div`
  ${text.headingLabel};
  color: ${colors.midnight};
  margin-bottom: 8px;
`

export const Item = styled.li<{ isBold?: boolean }>`
  ${text.bodyRegularDefault};
  border: solid ${colors.midnight10};
  border-width: 0 0 1px 0;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem;
  font-weight: ${(props: { isBold?: boolean }) =>
    props.isBold ? 'bold' : 'normal'};
`

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0;
`
