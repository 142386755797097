import { createSelector } from 'reselect'

import { createDateObject, isBefore } from 'packages/utils/dateHelpers'

import { ApplicationState } from '../../store'
import { HighlightsState } from '../highlights.types'

const getHighlightsState = (state: ApplicationState): HighlightsState =>
  state.highlights

/**
 * Returns all units currently available in the local store,
 * ordered by their `unitCode` field
 */
export const getDataForHighlights = createSelector(
  getHighlightsState,
  highlightsState => {
    const missedCleansHighlights = Object.keys(highlightsState.missedCleans)
      .map(id => {
        const missedClean = highlightsState.missedCleans[id]

        if (
          missedClean.attributes &&
          (missedClean.attributes.realtimeStatus ?? '').includes('overdue')
        ) {
          return {
            date: missedClean.attributes.effectiveDate,
            id: missedClean.id,
            type: 'missedClean',
            unitCode: highlightsState.units.find(
              unit =>
                unit.id === missedClean.relationships.unit.data.id.toString(),
            )?.code,
            unitId: missedClean.relationships.unit.data.id,
          }
        }

        return undefined
      })
      .filter(missedClean => missedClean !== undefined)

    const reservationsHighlights = Object.keys(highlightsState.reservations)
      .map(id => {
        const reservation = highlightsState.reservations[id]

        if (reservation.attributes) {
          return {
            bookingType: reservation.attributes.bookingType,
            date: reservation.attributes.checkIn,
            id: reservation.id,
            type: 'reservation',
            unitCode: highlightsState.units.find(
              unit =>
                unit.id === reservation.relationships.unit.data.id.toString(),
            )?.code,
            unitId: reservation.relationships.unit.data.id,
          }
        }

        return undefined
      })
      .filter(reservation => reservation !== undefined)

    const unitsWithVisits = Object.keys(highlightsState.visits || {})
      .filter(
        visitId =>
          highlightsState.visits[visitId]?.relationships?.unit?.data?.id !=
          null,
      )
      .map(visitId => {
        return highlightsState.visits[visitId].relationships.unit.data.id
      })

    const ticketsHighlights = Object.keys(highlightsState.tickets)
      .map(id => {
        const ticket = highlightsState.tickets[id]

        if (ticket.attributes) {
          return {
            date: createDateObject(),
            id: ticket.id,
            isVisitCreated: unitsWithVisits.includes(
              ticket.relationships.unit.data.id,
            ),
            severity: ticket.attributes.severity,
            type: 'ticket',
            unitCode: highlightsState.units.find(
              unit => unit.id === ticket.relationships.unit.data.id.toString(),
            )?.code,
            unitId: ticket.relationships.unit.data.id,
          }
        }

        return undefined
      })
      .filter(ticket => ticket !== undefined)

    const reviewsHighlights = Object.keys(highlightsState.reviews)
      .map(id => {
        const review = highlightsState.reviews[id]

        if (review.attributes) {
          return {
            date: review.attributes.reviewDate,
            id: review.attributes.reviewId,
            rating: review.attributes.overallRating,
            type: 'review',
            unitCode: highlightsState.units.find(
              unit => unit.id === review.relationships.unit.data.id.toString(),
            )?.code,
            unitId: review.relationships.unit.data.id,
          }
        }

        return undefined
      })
      .filter(review => review !== undefined)

    const mergedHighlights = [
      ...missedCleansHighlights,
      ...reservationsHighlights,
      ...ticketsHighlights,
      ...reviewsHighlights,
    ].sort((a, b) => {
      // First prioritize missed cleans
      if (a?.type === 'missedClean' && b?.type !== 'missedClean') return -1
      if (b?.type === 'missedClean' && a?.type !== 'missedClean') return 1

      // Then sort by date
      const aDate = createDateObject(a?.date ?? undefined)
      const bDate = createDateObject(b?.date ?? undefined)
      return isBefore(aDate, bDate) ? -1 : 1
    })

    return mergedHighlights
  },
)
