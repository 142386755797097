import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

export const TitlebarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.midnight};
  margin: 0;
  border-bottom: 1px solid ${colors.midnight60};
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const BrandIcon = styled(SvgIcon)`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${colors.white};
  padding: 15px;
  border-right: 1px solid ${colors.midnight60};
`

export const Tab = styled(Link)<{ active: 'true' | 'false' }>`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${colors.white};
  text-decoration: ${props => (props.active === 'true' ? 'underline' : 'none')};
  text-underline-offset: ${props =>
    props.active === 'true' ? '10px' : 'auto'};
  padding: 0 15px;
  border-right: 1px solid ${colors.midnight60};
  cursor: pointer;
`

export const MenuWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-left: 1px solid ${colors.midnight60};
`

export const MenuIcon = styled(SvgIcon)`
  color: ${colors.white};
  cursor: pointer;
`
