import { createAsyncThunk } from '@reduxjs/toolkit'

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { HousekeeperApiFields } from '../../housekeepers'
import { unitsService } from '../units.service'
import { UnitApiFields, UnitPreferencesApiFields } from '../units.types'

export const getParams = (zoneId: string): RequestOptions => ({
  fields: {
    'housekeeper_preferences.housekeeper': HousekeeperApiFields,
    housekeeper_unit_preference: UnitPreferencesApiFields,
    oncall_user: [
      'first_name',
      'last_name',
      'mobile_phone',
      'reason_for_assignment',
    ],
    unit: UnitApiFields,
  },
  filter: {
    is_active: true,
    zone: zoneId,
  },
  include: [
    'housekeeper_preferences.housekeeper',
    'housekeeper_preferences',
    'lock_box',
    'oncall_user',
    'manager',
  ],
  page: { size: 1500 },
})

export const fetchUnitsByZoneWithPreferences = createAsyncThunk(
  'units/api/fetchUnitsByZoneWithPreferences',
  async (zoneId: string) => {
    const params = getParams(zoneId)
    const result = await unitsService.fetchUnits(params)
    return result
  },
)
