import React from 'react'

import { Highlight } from '../HighlightsTable.types'
import * as St from './HighlightsTableRow.styles'
import { HighlightsTableRowMissedClean } from './HighlightsTableRowMissedClean'
import { HighlightsTableRowReservation } from './HighlightsTableRowReservation'
import { HighlightsTableRowReview } from './HighlightsTableRowReview'
import { HighlightsTableRowTicket } from './HighlightsTableRowTicket'
type HighlightsTableRowProps = {
  highlight: Highlight
}

export const HighlightsTableRow: React.FC<HighlightsTableRowProps> = ({
  highlight,
}) => {
  return (
    <>
      {highlight.type === 'reservation' && (
        <St.Container>
          <HighlightsTableRowReservation highlight={highlight} />
        </St.Container>
      )}
      {highlight.type === 'ticket' && (
        <St.Container>
          <HighlightsTableRowTicket highlight={highlight} />
        </St.Container>
      )}
      {highlight.type === 'missedClean' && (
        <St.MissedCleanContainer>
          <HighlightsTableRowMissedClean highlight={highlight} />
        </St.MissedCleanContainer>
      )}
      {highlight.type === 'review' && (
        <St.Container>
          <HighlightsTableRowReview highlight={highlight} />
        </St.Container>
      )}
    </>
  )
}
