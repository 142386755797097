import { datadogRum } from '@datadog/browser-rum'
import { startCase } from 'lodash/fp'
import React from 'react'

import { useManageDrawerState } from 'packages/common'
import { UnitRealtimeStatus } from 'packages/grimoire'
import { IconName } from 'packages/iconic'
import { createDateObject, createDateString } from 'packages/utils/dateHelpers'
import { getUserLogsContext } from 'packages/utils/misc'
import { Events, track } from 'packages/wiretap'

import {
  UnitFilterType,
  useUnitFiltersContext,
} from 'app/hkhub/components/schedule/components/units/contexts'
import {
  makeUnitOccupancyFilter,
  makeUnitOwnershipFilter,
  OccupancyType,
} from 'app/hkhub/components/schedule/components/units/utils'
import { useScheduleMatchParams } from 'app/hkhub/components/schedule/hooks'
import { useZoneContext } from 'app/hkhub/components/zone/ZonePage/ZonePage.context'
import { Slugs, useI18n } from 'app/hkhub/i18n'

import { LinkToggle } from '../LinkToggle/LinkToggle'
import { useScheduleContext } from '../VirtualizedSchedule/VirtualizedSchedule.context'
import {
  ClearFiltersButton,
  DrawerButtonIcon,
  DrawerButtonText,
  FilterControllsPillsContainer,
  FilterControlPill,
  FilterControlsContainer,
  FilterPill,
} from './FilterControls.styles'
import { useUnitStatusFilters } from './hooks'
import { OccupancyDrawer } from './OccupancyDrawer'
import { UnitStatusDrawer } from './UnitStatusDrawer'

const statusPillRenderOrder: UnitFilterType[] = [
  UnitFilterType.UnitStatus,
  UnitFilterType.Occupancy,
  UnitFilterType.Ownership,
]

export enum FilterControlsTestIds {
  activeFilterPill = 'FilterControls__activeFilterPill',
  activeSearchPill = 'FilterControls__activeSearchPill',
  clearSearch = 'FilterControls__clearSearch',
  openOccupancyDrawer = 'FilterControls__openOccupancyDrawer',
  openUnitStatusDrawer = 'FilterControls__openUnitStatusDrawer',
}

const useTranslations = () => {
  const { t, ut } = useI18n()

  return {
    all: t(Slugs.all),
    arrivalAndDeparture: ut(Slugs.arrivalAndDeparture),
    clearFilters: startCase(t(Slugs.clearFilters)),
    includesCoverage: t(Slugs.includesCoverage),
    myUnits: t(Slugs.myUnits),
    unitSearch: t(Slugs.unitSearch),
    unitStatus: t(Slugs.unitStatus),
  }
}

const UnitFilterControls: React.FC = () => {
  const strings = useTranslations()
  const { ut } = useI18n()
  const {
    addFilters,
    clearAllFilters,
    clearOccupancyFilters,
    clearSearchFilters,
    filters,
    removeFilters,
    searchFilters = [],
  } = useUnitFiltersContext()

  const {
    closeDrawer: closeOccupancyDrawer,
    completeDrawerClose: afterOccupancyDrawerExit,
    drawerState: occupancyDrawerState,
    openDrawer: openOccupancyDrawer,
  } = useManageDrawerState()

  const { openDrawer: openUnitStatusDrawer, ...unitStatusDrawerProps } =
    useUnitStatusFilters()

  const { zone } = useZoneContext()
  const { dateRange } = useScheduleContext()
  const userInfo = getUserLogsContext()

  const occupancyFilters = filters[UnitFilterType.Occupancy]
  const unitStatusFilters = filters[UnitFilterType.UnitStatus]
  const ownershipFilters = filters[UnitFilterType.Ownership]

  // any non-empty string in these filters means we have a valid filter for that associated type
  const hasActiveSearch = !!searchFilters[0]?.toString().length
  const hasActiveOccupancyFilter = !!occupancyFilters[0]?.toString().length
  const hasActiveUnitStatusFilter = !!unitStatusFilters[0]?.toString().length
  const hasActiveOwnershipFilter = !!ownershipFilters[0]?.toString().length

  const hasAnyActiveFilter =
    hasActiveSearch ||
    hasActiveOccupancyFilter ||
    hasActiveUnitStatusFilter ||
    hasActiveOwnershipFilter

  const applyOccupancyFilters = (
    selectedDate: string,
    selectedOccupancy: OccupancyType,
  ) => {
    clearOccupancyFilters()

    addFilters(UnitFilterType.Occupancy, [
      makeUnitOccupancyFilter(
        selectedOccupancy,
        createDateObject(selectedDate),
      ),
    ])

    /* eslint-disable @typescript-eslint/naming-convention */
    track(Events.hubFilterByOccupancy, {
      filter_date: createDateString(selectedDate),
      filter_occupancy: selectedOccupancy,
      zone_id: zone.id,
    })
    /* eslint-enable @typescript-eslint/naming-convention */

    closeOccupancyDrawer()
  }

  const applyMyUnitsFilter = () => {
    addFilters(UnitFilterType.Ownership, [
      makeUnitOwnershipFilter(userInfo.id ?? '', strings.myUnits),
    ])

    datadogRum.addAction('filterByOwnership')
  }

  return (
    <>
      <LinkToggle
        options={[
          {
            id: 'all',
            onClick: () =>
              removeFilters(UnitFilterType.Ownership, [ownershipFilters[0]]),
            title: strings.all,
          },
          {
            iconName: 'maintenance',
            id: 'my_units',
            onClick: () => applyMyUnitsFilter(),
            subtitle: strings.includesCoverage,
            title: strings.myUnits,
          },
        ]}
        selectedOption={hasActiveOwnershipFilter ? 'my_units' : 'all'}
      />

      <FilterControllsPillsContainer>
        <FilterControlPill
          data-testid={FilterControlsTestIds.openUnitStatusDrawer}
          onClick={openUnitStatusDrawer}
        >
          <DrawerButtonIcon icon={IconName.sliders} size={14} />
          <DrawerButtonText>{strings.unitStatus}</DrawerButtonText>
        </FilterControlPill>

        <FilterControlPill
          data-testid={FilterControlsTestIds.openOccupancyDrawer}
          onClick={openOccupancyDrawer}
        >
          <DrawerButtonIcon icon={IconName.sliders} size={14} />
          <DrawerButtonText>{strings.arrivalAndDeparture}</DrawerButtonText>
        </FilterControlPill>

        {hasActiveSearch && (
          <FilterPill
            data-testid={FilterControlsTestIds.activeSearchPill}
            onClose={clearSearchFilters}
          >
            {strings.unitSearch}
          </FilterPill>
        )}

        {statusPillRenderOrder.map(filterType => {
          const filtersByType = filters[filterType] ?? []

          return filtersByType.map(filter => {
            const filterString = filter.toString()

            return filterString ? (
              <FilterPill
                data-testid={FilterControlsTestIds.activeFilterPill}
                key={filterString}
                onClose={() => removeFilters(filterType, [filter])}
              >
                {filter.toUiLabel(ut)}
              </FilterPill>
            ) : null
          })
        })}

        {hasAnyActiveFilter && (
          <ClearFiltersButton
            dataTestId={FilterControlsTestIds.clearSearch}
            buttonType={'text'}
            onClick={clearAllFilters}
          >
            {strings.clearFilters}
          </ClearFiltersButton>
        )}

        <OccupancyDrawer
          activeSelectedDate={
            occupancyFilters[0] !== undefined
              ? occupancyFilters[0].toString().split('-')[0].replace(/\s+/g, '')
              : undefined
          }
          activeSelectedOccupancy={
            occupancyFilters[0] === undefined ||
            occupancyFilters[0].toString() === ''
              ? undefined
              : (occupancyFilters[0]
                  .toString()
                  .split('-')[1]
                  .trim() as OccupancyType)
          }
          afterExit={afterOccupancyDrawerExit}
          dateRange={dateRange}
          onFiltersApplied={applyOccupancyFilters}
          {...occupancyDrawerState}
        />

        <UnitStatusDrawer
          {...unitStatusDrawerProps}
          activeUnitStatusFilters={
            unitStatusFilters
              .map(filter => filter.toString())
              .filter(status => status !== '') as UnitRealtimeStatus[]
          }
        />
      </FilterControllsPillsContainer>
    </>
  )
}

export const FilterControls: React.FC = () => {
  const { entity } = useScheduleMatchParams()
  const isUnitView = entity === 'unit'

  return isUnitView ? (
    <FilterControlsContainer>
      <UnitFilterControls />
    </FilterControlsContainer>
  ) : null
}
