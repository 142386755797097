import React from 'react'

import { Slugs, useI18n } from 'app/hkhub/i18n'

import * as St from './HighlightsTable.styles'
import { Highlight } from './HighlightsTable.types'
import { HighlightsTableRow } from './rows/HighlightsTableRow'

type HighlightsTableProps = {
  highlights: Highlight[]
}

export const HighlightsTable: React.FC<HighlightsTableProps> = ({
  highlights,
}) => {
  const { t } = useI18n()

  return (
    <St.Container>
      {highlights.length === 0 && (
        <St.NoHighlights>{t(Slugs.noHighlights)}</St.NoHighlights>
      )}
      {highlights.map((highlight, index) => (
        <React.Fragment key={`highlights-table-row-${index}`}>
          <HighlightsTableRow highlight={highlight} />
          <St.Separator />
        </React.Fragment>
      ))}
    </St.Container>
  )
}
