import React from 'react'
import { useParams } from 'react-router-dom'

import { logError } from 'packages/wiretap/src/logging/logger'

import { reviewsService } from 'app/hkhub/store/reviews/reviews.service'
import { Review } from 'app/hkhub/store/reviews/reviews.types'

import { ReviewDetail } from '../../components/Reviews/ReviewDetail/'
import * as St from './ReviewPage.styles'

export enum ReviewPageTestIds {
  container = 'HKhub__ReviewPage__container',
  reviewData = 'HKhub__ReviewPage__reviewData',
}

export const ReviewPage: React.FC = React.memo(() => {
  const { reviewId } = useParams<{ reviewId: string }>()
  const [review, setReview] = React.useState<Review | undefined>()
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    const fetchReview = async () => {
      try {
        setIsLoading(true)
        const response = await reviewsService.fetchReviewById(reviewId || '')
        setReview(
          response?.normalized?.guestReview?.[reviewId || ''].attributes,
        )
      } catch (error) {
        logError(error)
      } finally {
        setIsLoading(false)
      }
    }

    if (reviewId) {
      fetchReview()
    }
  }, [reviewId])

  if (isLoading) {
    return <St.Content>Loading...</St.Content>
  }

  if (!review) {
    return <St.Content>Invalid Review Data</St.Content>
  }

  return (
    <St.Content data-testid={ReviewPageTestIds.container}>
      <St.ReviewData data-testid={ReviewPageTestIds.reviewData}>
        <ReviewDetail review={review} />
      </St.ReviewData>
    </St.Content>
  )
})
