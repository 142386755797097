import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  apiFilterExcludeCleans,
  apiFilterExcludeVisits,
} from 'packages/grimoire'
import {
  addDays,
  createDateObject,
  createDateString,
  subDays,
} from 'packages/utils/dateHelpers'
import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { housekeepersService } from '../../housekeepers/housekeepers.service'
import { reservationsService } from '../../reservations/reservations.service'
import { reviewsService } from '../../reviews/reviews.service'
import { tasksService } from '../../tasks/tasks.service'
import { TicketStatus } from '../../tickets'
import { ticketsService } from '../../tickets/tickets.service'
import { unitsService } from '../../units/units.service'

export const getHousekeepersParams = (userId: string): RequestOptions => ({
  filter: {
    user: userId,
  },
  include: ['zone', 'borrowed_zones'],
  page: { size: 1 },
})

export const getUnitsZoneParams = (zonesIds: string[]): RequestOptions => ({
  filter: {
    is_active: true,
    zone: { in: zonesIds },
  },
  include: ['oncall_user', 'manager'],
  page: { size: 5000 },
})

export const getReservationsParams = (unitsIds: string[]): RequestOptions => ({
  filter: {
    check_in: {
      gte: createDateString(),
      lte: createDateString(addDays(createDateObject(), 1)),
    },
    is_cancelled: false,
    unit: { in: unitsIds },
  },
  include: ['unit'],
  page: { size: 5000 },
})

export const getTicketsParams = (unitsIds: string[]): RequestOptions => ({
  filter: {
    severity: { in: [1, 2] },
    status: {
      in: [
        TicketStatus.UNASSIGNED,
        TicketStatus.RESOLUTION_PENDING,
        TicketStatus.ASSIGNED,
      ],
    },
    unit: { in: unitsIds },
  },
  include: ['unit'],
  page: { size: 5000 },
})

export const getVisitsParams = (unitsIds: string[]): RequestOptions => ({
  filter: {
    completed_at__isnull: true,
    effective_date: {
      gte: createDateString(createDateObject()).split('T')[0],
    },
    inspection_completed_at__isnull: true,
    is_active: true,
    unit: { in: unitsIds },
    ...apiFilterExcludeCleans,
  },
  include: ['unit'],
  page: { size: 5000 },
})

export const getMissedCleansParams = (unitsIds: string[]): RequestOptions => ({
  filter: {
    completed_at__isnull: true,
    effective_date: {
      exact: createDateString(subDays(createDateObject(), 1)).split('T')[0],
    },
    is_active: true,
    unit: { in: unitsIds },
    ...apiFilterExcludeVisits,
  },
  include: ['unit'],
  page: { size: 5000 },
})

export const getReviewsParams = (unitsIds: string[]): RequestOptions => ({
  filter: {
    review_date: {
      gte: createDateString(subDays(createDateObject(), 7)).split('T')[0],
    },
    unit_id: { in: unitsIds },
  },
  include: ['unit'],
  page: { size: 5000 },
})

export const fetchDataForHighlights = createAsyncThunk(
  'highlights/api/fetchDataForHighlights',
  async ({ userId }: { userId: string }) => {
    //first, fetch housekeeper data as thanks to that we will know in which zones the housekeeper is working
    const housekeeperParams = getHousekeepersParams(userId)
    const housekeeperResult = await housekeepersService.fetchHousekeepers(
      housekeeperParams,
    )

    if (!housekeeperResult.normalized.zone) {
      return {
        missedCleans: {},
        reservations: {},
        reviews: {},
        tickets: {},
        visits: {},
      }
    }

    const zonesIds = Object.keys(housekeeperResult.normalized.zone ?? {})

    // now. fetch all units in the zones where the housekeeper is working - this is because filtering by oncall user is not supported and even it is possible to create filter for that, on bigger size of units it is timeouting
    const unitsZoneParams = getUnitsZoneParams(zonesIds)
    const unitsZoneResult = await unitsService.fetchUnits(unitsZoneParams)
    const unitsWhereOncallUserOrManager = Object.keys(
      unitsZoneResult.normalized.unit,
    )
      .filter(unitId => {
        const unit = unitsZoneResult.normalized.unit[unitId]
        const oncallUser = unit.relationships.oncallUser.data?.id
        const manager = unit.relationships.manager.data?.id
        return oncallUser === userId || manager === userId
      })
      .map(unitId => ({
        code: unitsZoneResult.normalized.unit[unitId].attributes.unitCode,
        id: unitId,
      }))

    // combine all units where the user is oncall or manager
    const unitsWhereOncallOrManagerSerialized = Array.from(
      new Set([...unitsWhereOncallUserOrManager].map(unit => unit.id)),
    ).map(id => {
      const unit = unitsZoneResult.normalized.unit[id]
      return {
        code: unit.attributes.unitCode,
        id,
      }
    })

    //now get reservations for these units for today and tomorrow
    const reservationsParams = getReservationsParams(
      unitsWhereOncallOrManagerSerialized.map(unit => unit.id),
    )
    const reservationsResult = await reservationsService.fetchReservations(
      reservationsParams,
    )

    //now get tickets for these units with today and high urgency
    const ticketsParams = getTicketsParams(
      unitsWhereOncallOrManagerSerialized.map(unit => unit.id),
    )
    const ticketsResult = await ticketsService.fetchTickets(ticketsParams)

    //we also need visits data so we can tell user "visit was scheduled" when displaying tickets
    const visitsParams = getVisitsParams(
      unitsWhereOncallOrManagerSerialized.map(unit => unit.id),
    )
    const visitsResult = await tasksService.fetchTasks(visitsParams)

    //now get missed cleans for these units
    const missedCleansParams = getMissedCleansParams(
      unitsWhereOncallOrManagerSerialized.map(unit => unit.id),
    )
    const missedCleansResult = await tasksService.fetchTasks(missedCleansParams)

    //fetch also reviews for these units
    const reviewsParams = getReviewsParams(
      unitsWhereOncallOrManagerSerialized.map(unit => unit.id),
    )
    const reviewsResult = await reviewsService.fetchReviews(reviewsParams)

    return {
      missedCleans: missedCleansResult,
      reservations: reservationsResult,
      reviews: reviewsResult,
      tickets: ticketsResult,
      units: unitsWhereOncallOrManagerSerialized,
      visits: visitsResult,
    }
  },
)
