import React from 'react'

import { Button } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'

import { Slugs, useI18n } from 'app/hkhub/i18n'

import { Highlight } from '../HighlightsTable.types'
import * as St from './HighlightsTableRow.styles'

type HighlightsTableRowMissedCleanProps = {
  highlight: Highlight
}

export const HighlightsTableRowMissedClean: React.FC<
  HighlightsTableRowMissedCleanProps
> = ({ highlight }) => {
  const { t } = useI18n()

  return (
    <>
      <St.LeftPart>
        <SvgIcon icon={IconName.bucketClean} size={26} />
        <span>{`${t(Slugs.missedClean)}, ${t(Slugs.unitWord)} ${
          highlight.unitCode
        }`}</span>
      </St.LeftPart>
      <div>
        {highlight.isVisitCreated ? (
          <St.VisitScheduledText>
            {t(Slugs.visitScheduled)}
          </St.VisitScheduledText>
        ) : (
          <Button buttonType="utility">{t(Slugs.review)}</Button>
        )}
      </div>
    </>
  )
}
