import { HousekeeperAttributeNames } from 'packages/grimoire'
import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  HkEmployeeType,
  HkSearchResultsState,
  Housekeeper,
  HousekeeperTier,
  NormalizedHousekeepersApiResponse,
  RawHousekeeper,
} from './housekeepers.types'

/**
 * Empty data in the shape of the Housekeepers service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedHousekeeperData = Object.freeze({
  housekeeper: {},
  housekeeperTier: {},
  manager: {},
  relationshipUpdate: {},
  user: {},
  zone: {},
})

export const emptyNormalizedHkSearchResults: HkSearchResultsState =
  Object.freeze({
    housekeeper: {},
    user: {},
    zone: {},
  })

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptyHousekeepersResponse: NormalizedHousekeepersApiResponse =
  Object.freeze({
    normalized: emptyNormalizedHousekeeperData,
    raw: { data: [] },
  })

export const isAgency = (hk: Housekeeper): boolean =>
  hk.employeeType === HkEmployeeType.agency

/**
 * A fake HK record to use to represent an HK on unassigned cleans.
 * This allows us to simplify some of the schedule rendering logic
 * by treating unassigned cleans as if they WERE assigned by using this
 * HK in place of empty assignments. Cool!
 */
export const UnassignedHk: Housekeeper = Object.freeze({
  employeeType: HkEmployeeType.employee,
  id: 'unassigned',
  tier: {
    id: '',
    name: '',
    rank: 0,
  },
  user: {
    address: '',
    firstName: 'Unassigned',
    id: '',
    isHomeowner: false,
    isSuperuser: false,
    lastName: '',
    mobilePhone: '',
    monolithUserId: '',
  },
})

export const UnknownHk: Housekeeper = Object.freeze({
  employeeType: HkEmployeeType.employee,
  id: 'unknown',
  tier: {
    id: '',
    name: '',
    rank: 0,
  },
  user: {
    address: '',
    firstName: 'Unknown',
    id: '',
    isHomeowner: false,
    isSuperuser: false,
    lastName: '',
    mobilePhone: '',
    monolithUserId: '',
  },
})

export const UnknownTier: HousekeeperTier = Object.freeze({
  id: 'unknown',
  name: '',
  rank: 0,
})

export const hydrateRawHousekeeper = (
  rawAssignment: RawHousekeeper,
): Housekeeper => {
  const housekeeper = transformNormalizedToTyped<Housekeeper>(
    rawAssignment,
    HousekeeperAttributeNames,
  )

  return {
    ...housekeeper,
  }
}
