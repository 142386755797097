import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'

type LinkToggleOptionProps = {
  isSelected: boolean
}

export const LinkToggleWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`

export const LinkToggleContainer = styled.div`
  border: 1px solid white;
  border-radius: 4px;
  height: 40px;
  display: inline-flex;
  align-items: stretch;
  justify-content: center;
  padding: 3px;
  background-color: transparent;
`

export const LinkToggleOption = styled.div<LinkToggleOptionProps>`
  width: 100px;
  font-size: 14px;
  flex: 0 0 100px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isSelected }) => (isSelected ? 'rgb(0, 51, 73)' : 'white')};
  font-weight: bold;
  background-color: ${({ isSelected }) =>
    isSelected ? 'white' : 'transparent'};
`

export const LinkToggleOptionIcon = styled(SvgIcon)`
  margin-right: 5px;
`

export const LinkToggleOptionSubtitle = styled.div`
  font-size: 11px;
  color: white;
  opacity: 0.8;
  margin-top: 4px;
  text-align: center;
`
