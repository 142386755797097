import React from 'react'
import { useLocation } from 'react-router-dom'

import { useManageDrawerState } from 'packages/common'
import { IconName } from 'packages/iconic'

import { Slugs, useI18n } from 'app/hkhub/i18n'

import { NavDrawer } from './NavDrawer'
import * as St from './Titlebar.styles'

export enum TitleBarTestIds {
  container = 'HKhub__titleBar__container',
}

const TABS = ['overview', 'reviews'] as const

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    menu: ut(Slugs.menu),
    tabs: {
      overview: 'Overview',
      reviews: 'Reviews',
    },
  }
}

export const Titlebar: React.FC = () => {
  const strings = useTranslations()
  const drawerStateProps = useManageDrawerState()
  const { openDrawer } = drawerStateProps
  const location = useLocation()

  return (
    <St.TitlebarContainer data-testid={TitleBarTestIds.container}>
      <St.ContentWrapper>
        <St.BrandIcon centerItems={true} icon={IconName.vacasa2} size={26} />
        {TABS.map((tab, index) => (
          <St.Tab
            key={index}
            to={`/v2/${tab}`}
            active={`${location.pathname.includes(tab)}`}
          >
            {strings.tabs[tab]}
          </St.Tab>
        ))}
      </St.ContentWrapper>
      <St.ContentWrapper>
        <St.MenuWrapper>
          <St.MenuIcon
            centerItems={true}
            textFontSize={16}
            icon={IconName.menu}
            onClick={openDrawer}
            size={20}
            text={strings.menu}
          />
        </St.MenuWrapper>
      </St.ContentWrapper>
      <NavDrawer {...drawerStateProps} />
    </St.TitlebarContainer>
  )
}
