import { createSlice } from '@reduxjs/toolkit'

import { fetchDataForHighlights } from './actions/fetchDataForHighlights'
import { HighlightsState } from './highlights.types'

const initialState: HighlightsState = {
  error: undefined,
  missedCleans: {},
  reservations: {},
  reviews: {},
  tickets: {},
  visits: {},
}

const getNormalizedData = <T>(
  data,
  normalizedPath: string,
): Record<string, T> => {
  if (!data) return {}
  return data?.normalized?.[normalizedPath] || data || {}
}

export const highlightsSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchDataForHighlights.pending, state => {
        state.error = undefined
      })
      .addCase(fetchDataForHighlights.fulfilled, (state, action) => {
        state.units = action.payload.units || []

        state.missedCleans = getNormalizedData(
          action.payload.missedCleans,
          'task',
        )

        state.reservations = getNormalizedData(
          action.payload.reservations,
          'reservation',
        )

        state.reviews = getNormalizedData(action.payload.reviews, 'guestReview')
        state.tickets = getNormalizedData(action.payload.tickets, 'ticket')
        state.visits = getNormalizedData(action.payload.visits, 'task')
        state.error = undefined
      })
      .addCase(fetchDataForHighlights.rejected, (state, action) => {
        state.error = action.error.message
      })
  },
  initialState,
  name: 'highlights',
  reducers: {},
})

export default highlightsSlice.reducer
