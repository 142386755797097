import React from 'react'
import { Link } from 'react-router-dom'

import { Rating } from 'packages/common'
import { ReviewSource } from 'packages/grimoire/src/review/review.types'
import { SvgIcon } from 'packages/iconic'
import { format } from 'packages/utils/dateHelpers'
import { logError } from 'packages/wiretap/src/logging/logger'

import { useAppSelector } from 'app/hkhub/store/hooks'
import { reviewsService, ReviewSummary } from 'app/hkhub/store/reviews'
import { getAuthUserId } from 'app/hkhub/store/users/selectors'

import { FilterControls } from './components/FilterControls/FilterControls'
import { UnitOption } from './components/FilterControls/FiltersDrawer'
import {
  getSourceIcon,
  processGuestReviewNormalizedData,
  sortByReviewDate,
} from './UnitReviewsPage.service'

import styles from './UnitReviewsPage.module.scss'

export enum UnitReviewsPageTestIds {
  body = 'UnitReviewsPage__Table__body',
  header = 'UnitReviewsPage__Table__header',
  table = 'UnitReviewsPage__Table',
}

export type ReviewItemKey = keyof Omit<ReviewSummary, 'reviewId'>
export type ReviewColumn = {
  key: ReviewItemKey
  label: string
}

export const columns: ReviewColumn[] = [
  { key: 'reviewSource', label: 'Channel' },
  { key: 'reviewDate', label: 'Date' },
  { key: 'unitCode', label: 'Unit' },
  { key: 'overallRating', label: 'Rating' },
  { key: 'publicReviewText', label: 'Review' },
]

const REVIEW_ITEM_CONTAINER: Record<
  ReviewItemKey,
  (review: ReviewSummary) => React.ReactNode
> = {
  overallRating: (review: ReviewSummary) => (
    <Rating value={review.overallRating} />
  ),
  publicReviewText: (review: ReviewSummary) => (
    <div>{review.publicReviewText}</div>
  ),
  reviewDate: (review: ReviewSummary) => (
    <div className={styles.reviewDate}>
      {format(review.reviewDate, 'do MMM yyyy')}
    </div>
  ),
  reviewSource: (review: ReviewSummary) => (
    <SvgIcon icon={getSourceIcon(review.reviewSource)} size={24} />
  ),
  unitCode: (review: ReviewSummary) => (
    <div className={styles.unitCode}>{review.unitCode}</div>
  ),
}
const RATING_RANGES: { [key: number]: [number, number] } = {
  1: [0, 1.9],
  2: [2, 2.9],
  3: [3, 3.9],
  4: [4, 4.9],
  5: [5, 5.1],
}

export const UnitReviewsPage: React.FC = () => {
  const [reviews, setReviews] = React.useState<ReviewSummary[]>([])
  const [filteredReviews, setFilteredReviews] = React.useState<ReviewSummary[]>(
    [],
  )
  const userId = useAppSelector(getAuthUserId)

  React.useEffect(() => {
    if (userId) {
      reviewsService
        .fetchUnitReviewsForUser(userId)
        .then(res => {
          const normalizedReviews = processGuestReviewNormalizedData(res)
          setReviews(normalizedReviews)
          setFilteredReviews(normalizedReviews)
        })
        .catch(logError)
    }
  }, [userId])

  const applyFilters = React.useCallback(
    (
      selectedChannels: ReviewSource[],
      selectedRatings: number[],
      selectedUnits: UnitOption[],
    ) => {
      setFilteredReviews(
        reviews.filter(review => {
          const channelMatch =
            selectedChannels.length === 0 ||
            selectedChannels.includes(review.reviewSource)
          const ratingMatch =
            selectedRatings.length === 0 ||
            selectedRatings.some(rating => {
              const [min, max] = RATING_RANGES[rating]
              return review.overallRating >= min && review.overallRating <= max
            })
          const unitMatch =
            selectedUnits.length === 0 ||
            !!selectedUnits.find(unit => unit.value === review.unitCode)

          return channelMatch && ratingMatch && unitMatch
        }),
      )
    },
    [reviews],
  )

  return (
    <>
      <div className={styles.filtersContainer}>
        <FilterControls
          units={Array.from(new Set(reviews.map(review => review.unitCode)))}
          onApplyFilters={applyFilters}
        />
      </div>
      <div>
        <div
          className={styles.table}
          data-testid={UnitReviewsPageTestIds.table}
        >
          <div
            className={styles.header}
            data-testid={UnitReviewsPageTestIds.header}
          >
            {columns.map(column => (
              <div key={column.key} className={styles.headerCell}>
                {column.label}
              </div>
            ))}
          </div>
          <div
            className={styles.body}
            data-testid={UnitReviewsPageTestIds.body}
          >
            {filteredReviews
              .sort((a, b) => sortByReviewDate(a, b, 'desc'))
              .map(review => (
                <Link
                  key={review.reviewId}
                  className={styles.bodyRow}
                  to={`/v2/review/${review.reviewId}`}
                >
                  {columns.map(column => (
                    <div key={column.key} className={styles.bodyCell}>
                      {REVIEW_ITEM_CONTAINER[column.key](review)}
                    </div>
                  ))}
                </Link>
              ))}
          </div>
        </div>
      </div>
      <div className={styles.paginationContainer}></div>
    </>
  )
}
