import React from 'react'

import { Button } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { createDateObject, createDateString } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/hkhub/i18n'

import { Highlight } from '../HighlightsTable.types'
import * as St from './HighlightsTableRow.styles'

type HighlightsTableRowReservationProps = {
  highlight: Highlight
}

export const HighlightsTableRowReservation: React.FC<
  HighlightsTableRowReservationProps
> = ({ highlight }) => {
  const { t } = useI18n()

  const isToday =
    createDateString(createDateObject()).split('T')[0] ===
    createDateString(highlight.date).split('T')[0]

  const isOwner = highlight.bookingType === 'owner_hold'

  return (
    <>
      <St.LeftPart>
        <SvgIcon icon={isOwner ? IconName.crown : IconName.users} size={26} />
        <span>{`${isOwner ? t(Slugs.ownerArrival) : t(Slugs.guestArrival)} ${
          isToday
            ? t(Slugs.today).toLowerCase()
            : t(Slugs.tomorrow).toLowerCase()
        }, ${t(Slugs.unitWord)} ${highlight.unitCode}`}</span>
      </St.LeftPart>
      <div>
        <Button buttonType="utility">{t(Slugs.planVisit)}</Button>
      </div>
    </>
  )
}
