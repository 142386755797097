import styled from '@emotion/styled'

import { colors } from 'packages/styles'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${colors.midnight};
  margin-bottom: 100px;
  max-width: calc(100% - 20px);
`

export const NoHighlights = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.midnight};
`
