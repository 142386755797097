import styled from '@emotion/styled'

import { Drawer } from 'packages/common'
import { colors } from 'packages/styles'

export const StyledDrawer = styled(Drawer)`
  background: ${colors.midnight};
  border-left: none;
  padding-bottom: 0;
  min-width: 300px;
`

export const DrawerContents = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 50px;
`
