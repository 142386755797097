import { taskIsVisit } from 'packages/grimoire'
import { transformNormalizedToTyped } from 'packages/utils/store'

import { TaskJobType } from '../tasks'
import { AllVisitAttributeNames, RawVisit, Visit } from './visits.types'

export const hydrateRawVisit = (rawVisit: RawVisit): Visit => {
  const assignmentIds = (
    rawVisit.relationships?.activeAssignments?.data || []
  ).map(ass => ass.id)

  const assignedHkIds = (
    rawVisit.relationships?.assignedHousekeepers?.data || []
  ).map(ass => ass.id)

  const unitId = rawVisit.relationships?.unit?.data?.id || ''

  const taskPhotoIds = (rawVisit.relationships?.taskPhotos?.data || []).map(
    ({ id }) => id,
  )

  const inspectionFlagIds = (
    rawVisit.relationships?.inspectionFlags?.data || []
  ).map(({ id }) => id)

  const ticketIds = (rawVisit.relationships?.tickets?.data || []).map(
    ({ id }) => id,
  )

  const visit = transformNormalizedToTyped<Visit>(
    rawVisit,
    AllVisitAttributeNames,
  )

  return {
    ...visit,
    assignedHkIds,
    assignmentIds,
    inspectionFlagIds,
    taskPhotoIds,
    ticketIds,
    unitId,
  }
}

export const isRawVisit = (task: {
  attributes: { jobType: TaskJobType[] }
}): task is RawVisit => {
  return taskIsVisit(task.attributes)
}
