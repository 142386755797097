import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'

import { Loader } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'
import { DateFormat, formatLocalized } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Clean } from 'app/hkhub/store/cleans'
import { taskIsHubClean } from 'app/hkhub/store/cleans/cleans.utils'
import { getInspectionFlagsByIds } from 'app/hkhub/store/inspectionFlags/selectors/getInspectionFlagsByIds'
import { ApplicationState } from 'app/hkhub/store/store'
import { getTaskPhotosByIds } from 'app/hkhub/store/taskPhotos/selectors/getTaskPhotosByIds'
import { Visit } from 'app/hkhub/store/visits'

import { InspectionCategories } from './components/InspectionCategories'

const St = {
  InspectionIcon: styled(SvgIcon)`
    margin-right: 4px;
  `,

  InspectionSection: styled.div`
    margin-top: 24px;
  `,

  LoaderWrapper: styled.div`
    margin-top: 24px;
    position: relative;
  `,

  NoNoteContainer: styled.div`
    font-style: italic;
    padding: 12px 56px;
  `,

  NoteContainer: styled.div`
    padding: 12px 56px;
  `,

  NoteHeader: styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
  `,

  NoteTitle: styled.div`
    color: ${colors.midnight};
    flex-grow: 1;
    font-size: 16px;
    font-weight: bold;
    padding-left: 24px;
  `,

  SectionHeader: styled.div`
    padding-left: 8px;
  `,

  SectionTitle: styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 8px;
  `,

  Title: styled.div`
    font-weight: bold;
  `,
}

const useTranslations = (task: Clean | Visit) => {
  const { t } = useI18n()

  const completedAt = task.inspectionCompletedAt
  const statusString = completedAt
    ? t(Slugs.inspectionComplete, {
        date: formatLocalized(completedAt, DateFormat.FullWithoutTime),
        time: formatLocalized(completedAt, DateFormat.TimeWithAmPm),
      })
    : t(Slugs.inspectionNotComplete)

  return {
    inspectionNotes: t(Slugs.inspectionNotes),
    noInspectionNotes: t(Slugs.noInspectionNotes),
    status: statusString,
    title: t(Slugs.inspection),
  }
}

export type InspectionSectionProps = {
  loading: boolean
  task: Clean | Visit
}

export const InspectionSection: React.FC<InspectionSectionProps> = React.memo(
  ({ loading, task }) => {
    const strings = useTranslations(task)
    const { inspectionChecklist } = task
    const taskPhotos = useSelector((state: ApplicationState) =>
      taskIsHubClean(task)
        ? task.taskPhotos
        : getTaskPhotosByIds(state.taskPhotos, task.taskPhotoIds),
    )

    const inspectionFlags = useSelector((state: ApplicationState) =>
      taskIsHubClean(task)
        ? [] // Clean has no inspectionFlags implemented yet
        : getInspectionFlagsByIds(state, task.inspectionFlagIds),
    )

    if (inspectionChecklist) {
      return (
        <>
          <St.InspectionSection>
            <St.SectionHeader>
              <St.Title>{strings.title}</St.Title>
              <p>{strings.status}</p>
            </St.SectionHeader>

            <InspectionCategories
              photos={taskPhotos}
              inspectionFlags={inspectionFlags}
              inspectionChecklist={inspectionChecklist}
            />
          </St.InspectionSection>
          {!taskIsHubClean(task) && (
            <>
              <St.NoteHeader>
                <SvgIcon icon={IconName.note} size={16} />
                <St.NoteTitle>{strings.inspectionNotes}</St.NoteTitle>
              </St.NoteHeader>
              {task.inspectionNotes ? (
                <St.NoteContainer>{task.inspectionNotes}</St.NoteContainer>
              ) : (
                <St.NoNoteContainer>
                  {strings.noInspectionNotes}
                </St.NoNoteContainer>
              )}
            </>
          )}
        </>
      )
    }

    return loading ? (
      <St.LoaderWrapper>
        <Loader size={12} />
      </St.LoaderWrapper>
    ) : null
  },
)
