import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  ReviewsApiResponse,
  ReviewsServiceResponse,
  ReviewListServiceResponse,
} from './reviews.types'
import { emptyReviewResponse, emptyReviewListResponse } from './reviews.utils'

const URL = '/guest_reviews'

export const getParams = (userId: string): RequestOptions => ({
  fields: {
    guest_review: [
      'review_id',
      'overall_rating',
      'review_date',
      'public_review_text',
      'review_source',
    ],
    unit: ['unit_code'],
  },
  filter: {
    unit__manager_id: userId,
  },
  include: ['unit'],
  sort: ['-review_date'],
})

export const reviewsService = {
  async fetchReviews(params: RequestOptions = {}): ReviewsServiceResponse {
    const response: ReviewsApiResponse = await api.get(URL, {
      params,
    })

    return response?.data || emptyReviewResponse
  },

  async fetchReviewById(reviewId: string): ReviewsServiceResponse {
    const response: ReviewsApiResponse = await api.get(`${URL}/${reviewId}`)
    return response?.data || emptyReviewResponse
  },

  async fetchUnitReviewsForUser(userId: string): ReviewListServiceResponse {
    const params = getParams(userId)
    const response = await api.get(URL, { params })
    return response?.data || emptyReviewListResponse
  },
}
