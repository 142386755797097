import { ReviewSource } from 'packages/grimoire/src/review'
import { IconName } from 'packages/iconic'
import { createDateObject, differenceInDays } from 'packages/utils/dateHelpers'

import {
  ReviewListServiceResponse,
  ReviewSummary,
} from 'app/hkhub/store/reviews'

export const processGuestReviewNormalizedData = (
  response: Awaited<ReviewListServiceResponse>,
): ReviewSummary[] => {
  const guestReview = response.normalized.guestReview
  const unit = response.normalized.unit

  return Object.entries(guestReview).map(([_, review]) => ({
    overallRating: review.attributes.overallRating,
    publicReviewText: review.attributes.publicReviewText,
    reviewDate: review.attributes.reviewDate,
    reviewId: review.attributes.reviewId,
    reviewSource: review.attributes.reviewSource,
    unitCode: unit[review.relationships.unit.data.id].attributes.unitCode,
  }))
}

export const getSourceIcon = (reviewSource: ReviewSource): IconName => {
  switch (reviewSource) {
    case ReviewSource.AIRBNB:
      return IconName.airbnb
    case ReviewSource.BOOKING:
      return IconName.booking
    case ReviewSource.VRBO:
      return IconName.vrbo
    case ReviewSource.VACASA:
      return IconName.vacasaHold
    default:
      return IconName.vacasaHold
  }
}

export function sortByReviewDate(
  a: ReviewSummary,
  b: ReviewSummary,
  direction: 'asc' | 'desc' = 'desc',
): number {
  const dateA = createDateObject(a.reviewDate)
  const dateB = createDateObject(b.reviewDate)

  if (direction === 'asc') {
    return differenceInDays(dateA, dateB)
  }

  return differenceInDays(dateB, dateA)
}
