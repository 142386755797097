import React from 'react'
import { Outlet } from 'react-router-dom'

import { Titlebar } from './components/Titlebar'

export const Layout: React.FC = React.memo(() => {
  return (
    <>
      <Titlebar />
      <Outlet />
    </>
  )
})
