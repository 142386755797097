import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { DataTable } from 'packages/common'
import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

const PAGE_WIDTH = '1200px'

export const ContentWrapper = styled.div`
  margin-bottom: 72px;
`

export const HeaderWrapper = styled.h2`
  background-color: ${colors.midnight10};
  padding: 48px 32px 24px 32px;
  width: 100%;
`

export const HeaderText = styled.div`
  font-size: 21px;
  margin: auto;
  max-width: ${PAGE_WIDTH};
`

export const CancelEditIcon = styled(SvgIcon)`
  color: ${colors.midnight};
`

export const tableHeader = css`
  text-transform: capitalize;
`

export const UnitAddress = styled.div`
  font-size: 12px;
`

export const StyledDataTable = styled(DataTable)`
  margin: auto;
  max-width: ${PAGE_WIDTH};
`

export const TextWithSortIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
