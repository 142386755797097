import * as React from 'react'

import { IconName } from 'packages/iconic'

import {
  LinkToggleContainer,
  LinkToggleOption,
  LinkToggleOptionIcon,
  LinkToggleOptionSubtitle,
  LinkToggleWrapper,
} from './LinkToggle.styles'

export type LinkToggleOptionType = {
  iconName?: string
  id: string
  onClick: () => void
  subtitle?: string
  title: string
}

type LinkToggleProps = {
  options: [LinkToggleOptionType, LinkToggleOptionType]
  selectedOption?: string
}

export const LinkToggle: React.FC<LinkToggleProps> = ({
  options,
  selectedOption,
}) => {
  const selectedOptionData = options.find(opt => opt.id === selectedOption)

  return (
    <LinkToggleWrapper>
      <LinkToggleContainer>
        {(options as unknown as LinkToggleOptionType[]).map(option => {
          return (
            <LinkToggleOption
              key={option.id}
              onClick={option.onClick}
              isSelected={selectedOption === option.id}
            >
              {option.iconName && (
                <div data-testid="linktoggle-icon">
                  <LinkToggleOptionIcon
                    icon={option.iconName as IconName}
                    size={18}
                  />
                </div>
              )}
              {option.title}
            </LinkToggleOption>
          )
        })}
      </LinkToggleContainer>
      {selectedOptionData?.subtitle && (
        <LinkToggleOptionSubtitle>
          {selectedOptionData.subtitle}
        </LinkToggleOptionSubtitle>
      )}
    </LinkToggleWrapper>
  )
}
