import {
  findAllRelationshipIds,
  transformNormalizedToTyped,
} from 'packages/utils/store'

import {
  InspectionFlag,
  InspectionFlagAttributeNames,
  RawInspectionFlag,
} from './inspectionFlags.types'

export const hydrateRawInspectionFlag = (
  rawInspectionFlag: RawInspectionFlag,
): InspectionFlag => {
  const inspectionFlag = transformNormalizedToTyped<InspectionFlag>(
    rawInspectionFlag,
    InspectionFlagAttributeNames,
  )

  return {
    ...inspectionFlag,
    taskId: rawInspectionFlag.relationships?.task?.data?.id || '',
    ticketIds: findAllRelationshipIds(rawInspectionFlag, 'tickets'),
  }
}

export const emptyInspectionFlagsData = Object.freeze({
  inspectionFlag: {},
})
