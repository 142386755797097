import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Rating } from 'packages/common'

import { Slugs, useI18n } from 'app/hkhub/i18n'

import { Highlight } from '../HighlightsTable.types'
import * as St from './HighlightsTableRow.styles'

type HighlightsTableRowReviewProps = {
  highlight: Highlight
}

export const HighlightsTableRowReview: React.FC<
  HighlightsTableRowReviewProps
> = ({ highlight }) => {
  const { t } = useI18n()
  const navigate = useNavigate()

  const handleReviewClick = () => {
    navigate(`/v2/review/${highlight.id}`)
  }

  return (
    <>
      <St.LeftPart>
        <span>{`${t(Slugs.newGuestReview)}`}</span>
        <Rating value={highlight.rating || 0} />
        <span>{`, ${t(Slugs.unitWord)} ${highlight.unitCode}`}</span>
      </St.LeftPart>
      <div>
        <Button buttonType="utility" onClick={handleReviewClick}>
          {t(Slugs.review)}
        </Button>
      </div>
    </>
  )
}
