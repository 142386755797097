import styled from '@emotion/styled'

import { colors } from 'packages/styles'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding-left: 10px;
  padding-right: 10px;
`

export const MissedCleanContainer = styled(Container)`
  background-color: ${colors.alert10};
`

export const LeftPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

export const VisitScheduledText = styled.span`
  font-weight: 600;
`
