import styled from '@emotion/styled'

import { Pill } from 'packages/common'
import { colors } from 'packages/styles'

export const Container = styled.div`
  display: flex;
  gap: 8px;
`

export const FilterPill = styled(Pill)`
  background: ${colors.midnight};
  border: ${colors.white} 1px solid;
  color: ${colors.white};
  display: flex;
  gap: 6px;
`
