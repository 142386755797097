import styled from '@emotion/styled'

export const Content = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
`

export const ReviewData = styled.pre`
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 4px;
  overflow: auto;
  font-family: monospace;
`
