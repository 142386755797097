import React from 'react'

import { useManageDrawerState, Pill } from 'packages/common'
import { ReviewSource } from 'packages/grimoire/src/review'
import { IconName, SvgIcon } from 'packages/iconic'

import { FilterPill, Container } from './FilterControls.styles'
import { FiltersDrawer, UnitOption } from './FiltersDrawer'

export enum FilterControlsTestIds {
  container = 'FilterControls__container',
}

export type FilterControlsProps = {
  onApplyFilters: (
    activeChannels: ReviewSource[],
    activeRatings: number[],
    selectedUnits: UnitOption[],
  ) => void
  units: string[]
}

export const FilterControls: React.FC<FilterControlsProps> = ({
  units,
  onApplyFilters,
}) => {
  const [selectedChannels, setSelectedChannels] = React.useState<
    ReviewSource[]
  >([])
  const [selectedRatings, setSelectedRatings] = React.useState<number[]>([])
  const [selectedUnits, setSelectedUnits] = React.useState<UnitOption[]>([])

  const { closeDrawer, completeDrawerClose, drawerState, openDrawer } =
    useManageDrawerState()

  const onApply = React.useCallback(
    (channels: ReviewSource[], ratings: number[], units: UnitOption[]) => {
      setSelectedChannels(channels)
      setSelectedRatings(ratings)
      setSelectedUnits(units)
      onApplyFilters(channels, ratings, units)

      closeDrawer()
    },
    [closeDrawer, onApplyFilters],
  )

  const resetUnitsFilter = React.useCallback(() => {
    setSelectedUnits([])
    onApplyFilters(selectedChannels, selectedRatings, [])
  }, [onApplyFilters, selectedChannels, selectedRatings])

  const resetChannelsFilter = React.useCallback(() => {
    setSelectedChannels([])
    onApplyFilters([], selectedRatings, selectedUnits)
  }, [onApplyFilters, selectedRatings, selectedUnits])

  const resetRatingsFilter = React.useCallback(() => {
    setSelectedRatings([])
    onApplyFilters(selectedChannels, [], selectedUnits)
  }, [onApplyFilters, selectedChannels, selectedUnits])

  return (
    <Container data-testid={FilterControlsTestIds.container}>
      {selectedUnits.length > 0 && (
        <Pill
          size="small"
          badgeCount={selectedUnits.length}
          variant="secondary"
          onClose={resetUnitsFilter}
        >
          Units
        </Pill>
      )}
      {selectedChannels.length > 0 && (
        <Pill
          size="small"
          badgeCount={selectedChannels.length}
          variant="secondary"
          onClose={resetChannelsFilter}
        >
          Channels
        </Pill>
      )}
      {selectedRatings.length > 0 && (
        <Pill
          size="small"
          badgeCount={selectedRatings.length}
          variant="secondary"
          onClose={resetRatingsFilter}
        >
          Ratings
        </Pill>
      )}
      <FilterPill onClick={openDrawer}>
        <SvgIcon icon={IconName.sliders} size={14} />
        <span>Filter</span>
      </FilterPill>
      <FiltersDrawer
        selectedChannels={selectedChannels}
        selectedRatings={selectedRatings}
        selectedUnits={selectedUnits}
        units={units.map(unitCode => ({ label: unitCode, value: unitCode }))}
        afterExit={completeDrawerClose}
        onApply={onApply}
        {...drawerState}
      />
    </Container>
  )
}
