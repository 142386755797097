import React from 'react'

import { UseManageDrawerState } from 'packages/common'

import * as St from './NavDrawer.styles'

export type NavDrawerProps = Pick<
  UseManageDrawerState,
  'drawerState' | 'completeDrawerClose'
>

export const NavDrawer: React.FC<NavDrawerProps> = ({
  completeDrawerClose,
  drawerState,
}) => {
  return (
    <St.StyledDrawer {...drawerState} afterExit={completeDrawerClose}>
      <St.DrawerContents></St.DrawerContents>
    </St.StyledDrawer>
  )
}
