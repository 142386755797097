import { Slugs, useI18n } from 'app/hkhub/i18n'

export const useTranslations = (): Record<string, string> => {
  const { ut } = useI18n()

  return {
    accuracy: ut(Slugs.accuracy),
    checkin: ut(Slugs.checkin),
    cleanliness: ut(Slugs.cleanliness),
    comfort: ut(Slugs.comfort),
    communication: ut(Slugs.communication),
    facilities: ut(Slugs.facilities),
    location: ut(Slugs.location),
    onlineListing: ut(Slugs.onlineListing),
    privateReview: ut(Slugs.privateReview),
    propertyCondition: ut(Slugs.propertyCondition),
    publicReview: ut(Slugs.publicReview),
    reservation: ut(Slugs.reservation),
    reservationId: ut(Slugs.reservationId),
    reviewDate: ut(Slugs.reviewDate),
    reviewOverallRating: ut(Slugs.reviewOverallRating),
    reviewReply: ut(Slugs.reviewReply),
    service: ut(Slugs.service),
    staff: ut(Slugs.staff),
    unitId: ut(Slugs.unitId),
    unitTickets: ut(Slugs.unitTickets),
    value: ut(Slugs.value),
    viewHome: ut(Slugs.viewHome),
    viewReservation: ut(Slugs.viewReservation),
    viewTickets: ut(Slugs.viewOpenTickets),
    wifi: ut(Slugs.wifi),
  }
}
